#Accordion {

  height: 100%;
  margin-top: 30px;

  .accordion {
    height: 100%;
    background-color: #eee;
    color: #444;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 15px;
    transition: 0.4s;

    @media (max-width: 480px) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .arrow-down {
      width: 0;
      height: 0; 
      border-top: 16px solid white;
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      margin-left: auto;
  }

  .arrow-up {
      width: 0;
      height: 0; 
      border-bottom: 16px solid white;
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      margin-left: auto;
  }

  .button {
    color: #FFFFFF;
    font-family: Archer;
    font-style: italic;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    height: 80px;
    text-align: left;
    width: 100%;
    padding: 10px 20px;
    font-size: 18px;

    @media (min-width: 375px) {
      height: 90px;
      font-size: 24px;
    }

    @media (min-width: 768px) {
      width: 100%;
    }

    @media (min-width: 992px) {
      width: 100%;
      padding: 0 20px;
      font-size: 30px;
    }
  }

  h1 {
    margin-top: 20px;
    font-size: 24px;
    font-weight: bold;
  }
  
  .button1 {
    background: #F5A623;
  }

  .button2 {
    background: #F37928;
  }

  .button3 {
    background: #A2C800;
  }

  .button4 {
    background: #669900;
  }

  .button5 {
    background: #009CBA;
  }

  .button-pp {
    background: #83CFDD
  }
  
  .panel {
    width: 100%;
    background-color: white;
    max-height: 0;
    overflow: hidden;
    box-sizing: border-box;
    padding: 5px 20px;
    font-weight: 300;
    font-family: Rubik;
    font-size: 20px;
    color: #404040;
    letter-spacing: 0;
    line-height: 28px;
    vertical-align: middle;

    @media (min-width: 992px) {
      font-size: 18px;
      line-height: 24px;
      padding: 5px 18px;
      width: 100%;
      box-sizing: content-box;
    }

    .arrow {
      width: 0;
      height: 0; 
      border-top: 16px solid #A2C800;
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      margin: auto;
    }

    .flowchart {
      margin: 10px 0;
      border: 1px solid #404040;
      display: flex;
      flex-direction: row;

      .flowchart-text {
        display: flex;
        flex-direction: column;
        padding: 8px 22px;
      }

      .rectangle {
        border-right: 1px solid #404040;
        flex-shrink: 0;
        width: 20px; 
      }
     
      .dark-blue {
         background-color: #009CBA;
      }

      .light-blue {
        background-color: #83CFDD;
      }

      .light-orange {
        background-color: #FAAC1D;
      }

      .dark-orange {
        background: #F37928;
      }
    }

    .flowchart.top {
      margin-top: 30px;
    }

    .flowchart.bottom {
      margin-bottom: 30px;
    }

    em {
      font-style: italic;
    }
    strong {
      font-family: Rubik;
      font-weight: normal;
    }
    .pdfLink {
      font-family: Rubik;
      font-weight: normal;
      margin-top: 10px;
      display: flex;
      flex-direction: row;
      margin-bottom: 15px;

      img {
        height: 32px;
      }
    }

    .section3Images {
      margin-top: 20px;
      margin-bottom: 30px;
      background-image: url('../shared/GreenOval.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: auto 100%;
      height: 350px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      img {
        height: 300px;
      }
      @media (max-width: 480px) {
        height: 300px;
        img {
          height: 230px;
        }
      }

    }

    a {
      margin-left: 5px;
      text-decoration: underline;
      font-family: Rubik;
      color: #669900;
    }

    .textPart {
      margin-top: 1px;
      margin-bottom: 15px;

      a {
        margin-left: 0px;
      }
    }
  }
  
  .panel.active {
    width: 100%;
    padding: 30px 20px 40px 20px;
    max-height: 100%;

    @media (min-width: 768px) {
      padding: 30px 10px 40px 10px;
    }

    @media (min-width: 992px) {
      padding: 30px 10px 40px 10px;
      width: 100%;
    }
  }

  ul {
    list-style-type: disc;

    li {
      margin-left: 18px;
    }
  }
}
