#Footer {

  padding: 0 35px;
  justify-content: space-evenly;
  background-color: #404040;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 80px;

  @media (min-width: 768px) {
    justify-content: flex-start;
    padding-left: 30px;
  }

  @media (min-width: 992px) {
    padding-right: unset;
    padding-left: 30px;
  }


  img {
    height: 40px;
    margin: auto 9px;

    @media (min-width: 360px) {
      height: 40px;
    }

    @media (min-width: 480px) {
      height: 40px;
      margin: auto 10px;
    }

    @media (min-width: 768px) {
      height: 50px;
      margin: 0 30px 0 0;
    }
  }

  img.pp {
    height: 30px;

    @media (min-width: 360px) {
      height: 35px;
    }

    @media (min-width: 480px) {
      height: 35px;
    }
  }
}
