#HeaderNav {
  width: 100%;

  @media (max-width: 767px) {
    display: none;
  }

  .menuRow {
    display: flex;
    flex-direction: row;
    margin-top: 5px;
    align-items: center;

  }

  .title {
    margin-left: 30px;
    margin-right: 10px;

    @media (min-width: 992px) {
      font-size: 24px;
    }
  }

  img {
    margin-top: 10px;
    height: 46px;
  }

  .menu {
    margin-left: 14px;
    text-align: left;
    font-size: 15px;
    font-family: Rubik;
    font-weight: normal;

    .homeLink:hover ~ hr {
      margin-left: 18px;
      background: #F37928;

      @media (min-width: 992px) {
        margin-left: 33px;
      }
    }

    .providerLink:hover ~ hr {
      margin-left: 135px;
      background: #669900;

      @media (min-width: 992px) {
        margin-left: 160px;
      }
    }
    
    .patientLink:hover ~ hr {
      margin-left: 274px;
      background: #009CBA;

      @media (min-width: 992px) {
        margin-left: 305px;
      }
    }
    
    .aboutLink:hover ~ hr {
      margin-left: 360px;
      background: #006477;
 
      @media (min-width: 992px) {
        margin-left: 395px;
      }
    }

    a.homeLink:hover {
      color: #F37928;
    }

    a.homeLink.home {
      color: #F37928;
    }

    a.providerLink:hover {
      color: #669900;
    }

    a.providerLink.providers {
      color: #669900;
    }

    a.patientLink:hover {
      color: #009CBA;
    }

    a.patientLink.patients {
      color: #009CBA;
    }

    a.aboutLink:hover {
      color: #006477;
    }

    a.aboutLink.about {
      color: #006477;
    }


    a {
      display: inline-block;
      padding: .5rem 8px;
      font-weight: normal;
      margin: 0;
      text-decoration: none;
      color: #808080;
      text-align: center;
      cursor: pointer;
 
      @media (min-width: 992px) {
        padding: .5rem 0 .5rem 23px;
      }
    }

    hr {
      height: .25rem;
      width: 20px;
      margin: 0 0 0 18px;
      border: none;
      border-radius: 5px;
      transition: .3s ease-in-out;
      font-family: Rubik;
      font-weight: bold;
      background: #F37928;

      @media (min-width: 992px) {
        margin-left: 33px;
      }
    };

    hr.providers {
      margin-left: 135px;
      background: #669900;

      @media (min-width: 992px) {
        margin-left: 160px;
      }
    }

    hr.patients {
      margin-left: 274px;
      background: #009CBA;

      @media (min-width: 992px) {
        margin-left: 305px;
      }
    }

    hr.about {
      margin-left: 360px;
      background: #006477;

      @media (min-width: 992px) {
        margin-left: 395px;
      }
    }
  }
}