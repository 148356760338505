#RainbowBar {
    
  .headerRainbow {
    display: flex;
    flex-direction: row;
    height: 15px;
    width: 100vw;

    @media (min-width: 1280px) {
      width: unset;
    }

    .lightOrange {
      background-color: #FAAC1D;
      height: 100%;
      width: 16.67%
    }
    .orange {
      background-color: #F37928;
      height: 100%;
      width: 16.67%
    }
    .lightGreen {
      background-color: #A2C800;
      height: 100%;
      width: 16.67%
    }
    .green {
      background-color: #669900;
      height: 100%;
      width: 16.67%
    }
    .lightBlue {
      background-color: #83CFDD;
      height: 100%;
      width: 16.67%
    }
    .blue {
      background-color: #009CBA;
      height: 100%;
      width: 16.67%
    }
  }

  .headerRainbow.small {
    height: 5px;
    width: 100%;
  }
}