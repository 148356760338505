#Patients {

  background-color: white;

  @media (min-width: 992px) {
    align-items: flex-start;
  }

  @media (min-width: 1280px) {
    margin: 0 auto;
    width: 1280px;
  }

  .outer-layout
  {
    width: 100%;

    @media (min-width: 992px) {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
    }
  }

  .layout {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;

    .row-or-column {
      display: flex;
      flex-direction: column;
      align-items: center;

      @media (min-width: 768px) {
        flex-direction: row;
        align-items: flex-start;
        width: 100%;
      }
    }
  }

  .for_patients {
    background: #009CBA;
    margin-top: 55px;
    padding: 10px;
    font-family: Archer;
    font-weight: bold;
    font-size: 22px;
    color: #FFFFFF;
    text-align: left;
    letter-spacing: 0.21px;
    align-self: flex-start;

    @media (min-width: 768px) {
      margin-top: 0px;
      font-size: 25px;
      letter-spacing: 0.25px;
    }

    @media (min-width: 992px) {
      display: inline-block;
      font-size: 34px;
      letter-spacing: 0.3px;
    }
  }


  .logo {
    background-image: url('./patients-circle-sm@2x.png');
    background-repeat: no-repeat;
    background-size: 90%;
    background-position: center 10px;
    width: 100%;
    height: 95vw;
    display: flex;
    flex-direction: column;

    @media (min-width: 768px) {
      width: 350px;
      height: unset;
      background-size: 350px;
      background-position: center 25px;
    }

    @media (min-width: 992px) {
      width: 100%;
      background-image: url('../shared/blueCircle.png');
      background-position: top 20px right 30px;
      //background-size: 400px;
      background-size: calc(400px + (100vw - 992px)/2.88);
    }

    @media (min-width: 1280px) {
      background-size: 500px;
    }


    img.laptopPic {
      padding-top: 20px;
      margin-top: 20px;
      width: 85%;
      align-self: center;

      @media (min-width: 768px) {
        margin-top: 35px;
        width: 330px;
      }

      @media (min-width: 992px) {
        align-self: flex-end;
        margin-top: 35px;
        margin-right: 40px;
        padding-left: 15px;
        width: calc(380px + (100vw - 992px)/3.03);
      }

      @media (min-width: 1280px) {
        width: 475px;
      }
    }

    img.phonePic {
      align-self: flex-start;
      width: 50%;
      margin: 5px 0 10px 35px;

      @media (min-width: 768px) {
        margin: 5px 0 10px 5px;
        width: 200px;
      }

      @media (min-width: 992px) {
        margin-top: 5px;
        margin-left: 5px;
        width: calc(235px + (100vw - 992px)/4.9);
      }

      @media (min-width: 1280px) {
        width: 294px;
      }
    }
  }

  .content {
    margin: 25px 20px 0;
    text-align: left;
    font-weight: 300;
    font-family: Rubik;

    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: 768px) {
      margin-left: 30px;
      margin-right: 5px;
      width: 350px;
      margin-bottom: 10px;
    }

    @media (min-width: 992px) {
      width: 100%;
    }

    strong {
      font-weight: bold;
    }
  }

  .more-content {
    margin: 30px 20px 20px 20px;

    @media (min-width: 768px) {
      margin: 0 30px;
    }

    @media (min-width: 992px) {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }
  }

  .choosing {
    //width: 100%;
    box-sizing: border-box;
    line-height: 32px;
    margin-top: 25px;
    margin-bottom: 15px;
    font-size: 24px;
    font-family: Georgia;
    color: #404040;
    letter-spacing: 0;

    @media (min-width: 768px) {
      font-size: 28px;
      line-height: 42px;
    }

    @media (min-width: 992px) {
      width: unset;
      margin-top: 30px;
      font-size: 34px;
      line-height: 48px;
      padding-right: 10px;
    }
  }

  .section_head {
    font-family: Rubik;
    font-weight: bold;
    font-size: 24px;
    color: #404040;
    letter-spacing: 0;
    line-height: 28px;

    @media (min-width: 992px) {
      line-height: 48px;
      padding-left: 30px;
    }
  }

  .section_head.what_can_i_do {
    margin-top: 20px;
  }

  .explanatory {
    margin-top: 20px;
    font-weight: 300;
    font-family: Rubik;
    font-size: 20px;
    color: #404040;
    letter-spacing: 0;
    line-height: 28px;
    width: 100%;

    @media (min-width: 992px) {
      font-size: 18px;
      line-height: 24px;
      width: unset;
      padding-left: 30px;
    }
  }
   
  .explanatory.continue {
    margin-top: 0;
  }

  ul {
    list-style-type: disc;

    li {
      margin-left: 18px;
    }
  }

  .visitDiv {
    justify-content: center;
    margin: 20px auto;

    @media (min-width: 768px) {
      justify-content: center;
      margin: 30px auto 40px;
    }

    @media (min-width: 992px) {
      margin: 30px auto 40px auto;
    }
  }

  .footer {
    margin-top: 20px;
    align-self: flex-end;
    width: 100%;
  }

  em {
    font-style: italic;
  }

  .desktop-only {
    @media (max-width: 991px) {
      display: none;
    }
  }

  .mobile-tablet-only {
    @media (min-width: 992px) {
      display: none;
    }
  }
}
