#VisitButton {

  .visitButton {
    height: 90px;
    width: 290px;
    font-size: 22px;
    letter-spacing: 0;
    text-align: center;
    border-radius: 50px;
    background-color: #009CBA;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.1);
    font-family: Georgia-Italic;
    color: #FFFFFF;
    display: flex;
    flex-direction: row;
    align-items: center;

    @media (min-width: 768px) {
      margin-top: 0;
      height: 80px;
      width: 350px;
      font-size: 24px;
    }

    .visitText {
      margin-left: 15px;

      @media (min-width: 768px) {
        margin-left: 40px;
      }
    }

    .arrow-right {
      width: 0;
      height: 0; 
      border-top: 12px solid transparent;
      border-bottom: 12px solid transparent;
      border-left: 16px solid white;
      position: relative;
      margin-left: 20px;

      @keyframes wiggle {
        from { margin-left: 20px }
        50% { margin-left: 20px }
        57% {  margin-left: 30px }
        71% { margin-left: 20px }
        78% { margin-left: 30px }
        92% { margin-left: 20px }
        to { margin-left: 20px }
      }

      &.wiggle {
        animation-name: wiggle;
        animation-duration: 2s;
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
        animation-delay: 1.5s;
      }
    }
  }
}
