#Hamburger {

  @media (min-width: 768px) {
    display: none;
  }

  .mybc {
    position: absolute;
    top: 37px;
    left: 20px;

    img {
      height: 45px;
    }
  }

  
  .mybc.in-menu {
    position: static;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    img {
      margin-left: 20px;
      margin-top: 22px;

    }
  }

  .top-bar.fixed {
    position: absolute;
    top: -15px;
  }

  .menu-item {
    display: block;
    margin: 50px 0;
    font-family: Rubik;
    font-weight: normal;
    font-size: 30px;
    letter-spacing: 0;
    text-align: center;
    color: #808080;
  }

  #home.menu-item.home {
    color: #F37928;
    hr {
      background: #f37928
    }
  }

  #providers.menu-item.providers {
    color: #669900;
    hr {
      background: #669900;
    }
  }

  #patients.menu-item.patients {
    color: #009CBA;
    hr {
      background: #009CBA;
    }
  }

  #about.menu-item.about {
    color: #006477;
    hr {
      background: #006477
    }
  }

  hr {
    height: .25rem;
    width: 20px;
    margin: 5px auto;
    border: none;
    //background: #F37928;
  };

  .bm-item {
    display: inline-block;
  
    /* Our sidebar item styling */
    text-decoration: none;
    margin-bottom: 10px;
    color: #d1d1d1;
    transition: color 0.2s;
  }
  
  /* Change color on hover */
  .bm-item:hover {
    color: white;
  }
  
  /* The rest copied directly from react-burger-menu docs */
  
  /* Position and sizing of burger button */
  .bm-burger-button {
    width: 30px !important;
    height: 30px;

    //position: relative;
    //margin-left: 20px;
    //margin-top: 25px;
    //margin-left: 86%;

    //position: fixed;
    //top: 26px;
    //right: 25px;

    position: absolute;
    margin-left: 20px;
    margin-top: 25px;
    margin-left: 86%;
  }
  
  
  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    width: 30px !important;
    height: 30px !important;
    right: 34px !important;
    top: 22px !important;
  }
  
  /* Color/shape of close button cross */
  .bm-cross {
    //background: #bdc3c7;
  }
  
  /* General sidebar styles */
  .bm-menu {
    background: white;
    //padding: 2.5em 1.5em 0;
    font-size: 1.15em;
    height: 508px !important;
  }
  
  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }
  
  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;

    .two-lines {
      display: flex;
      flex-direction: column;
    }
  }

  
  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }

}