#Home {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;

  @media (min-width: 768px) {
    min-height: 100vh;
  }

  @media (min-width: 1280px) {
    margin: 0 auto;
    width: 1280px;
  }

 .layout {
    display: flex;
    flex-grow: 1;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    @media (min-width: 768px) {
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
    }

    @media (min-width: 992px) {
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
    }

    @media (min-width: 1280px) {
      justify-content: center;
    }

  }

  .carousel {
    margin: auto;
    order: 0;

    @media (min-width: 992px) {
      margin: unset;
      margin-right: 80px;
      order: 2;
    }
  }

  .content {
      margin: 5px 20px 0 20px;
      padding-top: 0px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      flex-grow: 1;
      order: 1;

    @media (min-width: 768px) {
      justify-content: flex-start;
      margin-top: 0;
      margin-left: 30px;
      margin-right: 30px;
    }

    @media (min-width: 992px) {
      align-items: flex-start;
      min-width: 480px;
      //max-width: 650px;

      padding-top: 55px;
      margin-left: 30px;
      margin-right: 00px;
    }

    .introduction {
      margin-top: 0;
      font-family: Archer;
      font-weight: bold;
      font-size: 52px;
      text-align: center;
      line-height: 60px;
      color: #FAAC1D;
      letter-spacing: 0;

      @media (min-width: 768px) {
        font-size: 55px;
        text-align: left;
        line-height: 102px;
      }

      @media (min-width: 992px) {
        font-size: 64px;
        line-height: 77px;
      }
    }

    .introduction.first-line {
      text-align: left;
    }

    .visitDiv {
      margin: 10px 0;

      @media (min-width: 768px) {
        margin: 25px 0;
      }
    }

    .tool {
      margin: 10px 0;
      font-size: 20px;
      line-height: 28px;
      text-align: center;
      font-family: Georgia;
      color: #404040;
      letter-spacing: 0;

      @media (min-width: 768px) {
        margin: 10px 30px 20px;
        font-size: 22px;
        line-height: 30px;
      }

      @media (min-width: 992px) {
        text-align: left;
        margin: 40px 0;
        line-height: 48px;
        font-size: 34px;
      }
    }
  }

  .disclosure {
    margin: 20px;
    font-weight: 300;
    font-family: Rubik;
    flex-grow: 2;

    @media (min-width: 768px) {
      margin: 10px 30px 20px 30px;
    }

    @media (min-width: 992px) {
      margin: 50px 0 20px;
    }

    p {
      text-align: left;
      font-family: Rubik;
      font-weight: 300;
      font-size: 12px;
      color: #404040;
      letter-spacing: 0;
      line-height: 16px;
    }

  }

  .tablet-only {
    @media (min-width: 992px) {
      display: none;
    }

    @media (max-width: 767px) {
      display: none;
    }
  }

  .desktop-mobile-only {

    @media (min-width: 768px) and (max-width: 991px) {
      display: none;
    }
  }

  .footer {
    align-self: flex-end;
    width: 100%;
  }

  em {
    font-style: italic;
  }
}
