#About {

  background-color: white;

  @media (min-width: 992px) {
    align-items: flex-start;
  }

  @media (min-width: 1280px) {
    margin: 0 auto;
    width: 1280px;
  }


  .outer-layout
  {
    width: 100%;

    @media (min-width: 992px) {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
    }
  }

  .layout {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;

    .row-or-column {
      display: flex;
      flex-direction: column;
      align-items: center;

      @media (min-width: 768px) {
        flex-direction: row;
        align-items: flex-start;
      }
    }
  }

  .about_us {
    background: #006477;
    margin-top: 55px;
    padding: 10px;
    font-family: Archer;
    font-weight: bold;
    font-size: 22px;
    color: #FFFFFF;
    text-align: left;
    letter-spacing: 0.21px;
    align-self: flex-start;

    @media (min-width: 768px) {
      margin-top: 0px;
      font-size: 25px;
      letter-spacing: 0.25px;
    }

    @media (min-width: 992px) {
      margin-left: 30px;
      display: inline-block;
      font-size: 34px;
      letter-spacing: 0.3px;
    }
  }

  .logo {
    background-image: url('./about-circle-sm@2x.png');
    background-repeat: no-repeat;
    background-size: 90%;
    background-position: center 10px;
    width: 100%;
    height: 95vw;
    display: flex;
    flex-direction: column;

    @media (min-width: 768px) {
      width: 350px;
      height: unset;
      background-size: 350px;
      background-position: center 25px;
    }

    @media (min-width: 992px) {
      width: 100%;
      background-image: url('./AboutCircle.svg');
      background-position: top 20px right 30px;
      //background-size: 400px;
      background-size: calc(400px + (100vw - 992px)/2.88);
    }

    @media (min-width: 1280px) {
      background-size: 500px;
    }

    img {
      display: block;
      margin: 30px auto 0;
      padding: 85px 0;
      width: 65%;

      @media (min-width: 768px) {
        margin-top: 35px;
        width: 300px;
      }

      @media (min-width: 992px) {
        align-self: flex-end;
        padding: 100px 0 100px 35px;
        padding: 100px 0 calc(100px + (100vw - 992px)/5.76) calc(35px + (100vw - 992px)/11.52);
        margin-top: calc(30px + (100vw - 992px)/9.6);
        margin-right: 80px;
        width: 310px;
        width: calc(310px + (100vw - 992px)/3.72);
      }

      @media (min-width: 1280px) {
        margin-top: 60px;
        padding: 100px 0 150px 60px;
        width: 388px;
      }
    }
  }

  em {
    font-style: italic;
  }

  .content {
    margin: 25px 20px 0;
    text-align: left;
    font-weight: 300;
    font-family: Rubik;

    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: 768px) {
      margin-left: 30px;
      margin-right: 5px;
      width: 350px;
      margin-bottom: 10px;
    }

    @media (min-width: 992px) {
      width: 100%;
      margin-left: 0;
    }

    strong {
      font-weight: bold;
    }
  }

  .more-content {
    margin-bottom: 10px;
    margin-left: 20px;
    margin-right: 20px;

    @media (min-width: 768px) {
      margin-left: 30px;
      margin-right: 30px;
    }

    @media (min-width: 992px) {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }
  }

  .explanatory {
    margin-top: 20px;
    font-weight: 300;
    font-family: Rubik;
    font-size: 20px;
    color: #404040;
    letter-spacing: 0;
    line-height: 28px;
    //width: 100%;

    @media (min-width: 992px) {
      font-size: 18px;
      line-height: 24px;
      width: unset;
      padding-left: 30px;
      padding-right: 10px;
    }
  }

  .partners {
    margin: 20px 20px 20px 0;

    @media (min-width: 768px) {
      margin-top: 20px;  
      margin-left: 0;
    }

    @media (min-width: 992px) {
      padding-left: 30px;
    }
  }

  .partnerLogos {
    display: flex;
    align-items: flex-start;
    align-items: center;
    flex-direction: column;

    .ptbi {
      margin: 20px 0 0 0 ;
    }

    img {
      height: 150px;
    }

    @media (min-width: 768px) {
      flex-direction: row;
      margin: 0 20px 10px;

      .ptbi {
        margin-left: 30px;
      }
    }

    @media (min-width: 992px) {
      flex-direction: row;
      margin-bottom: 10px;
      margin-top: 10px;
      padding-left: 30px;
    }
  }

  h2 {
    font-family: Rubik;
    font-weight: bold;
    font-size: 24px;
    color: #404040;
    letter-spacing: 0;
    line-height: 28px;
    margin-left: 0;
  }

  a {
    margin-left: 5px;
    text-decoration: underline;
    font-family: Rubik;
    color: hsl(80, 100%, 30%);
  }

  .footer {
    align-self: flex-end;
    width: 100%;
  }

  .desktop-only {
    @media (max-width: 991px) {
      display: none;
    }
  }

  .mobile-tablet-only {
    @media (min-width: 992px) {
      display: none;
    }
  }
}
