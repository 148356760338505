#Carousel {
    width: 100vw;
    height: 100%;
    background-size: 300px;
    background-position: center 80px;
    background-image: url('./home-circle-sm@2x.png');
    background-repeat: no-repeat;
    display: block;

  @media (min-width: 768px) {
    width: 100%;
    background-image: url('../shared/orangeCircle.png');
    background-size: 418px;
    background-position: center;
  }

  @media (min-width: 992px) {
    width: 480px;
    background-position: 0 30px;
    background-size: 480px;
  }

  .slick-slider {
    width: 100%;

    @media (min-width: 768px) {
      width: 484px;
    }
  }

  .slick-slide {
    height: 400px;
    color: white;

    @media (min-width: 768px) {
      height: 499px;
    }

    @media (min-width: 992px) {
      height: 550px;
    }

    img {
      position: absolute;
      top: 70px;
      height: 320px; 
      margin-left: -140px; // To horizontally center something absolutely positioned
      left: 50%; // To horizontally center something absolutely positioned

      @media (min-width: 768px) {
        top: 10px;
        height: 479px; 
        margin-left: -210px; // To horizontally center something absolutely positioned
        //margin-left: -200px; // To horizontally center something absolutely positioned

        left: 50%; // To horizontally center something absolutely positioned
      }

      @media (min-width: 992px) {
        height: 550px;
        top: 0px;
        left: 0px;
        margin-left: unset;
      }
    }
  }
}
